<script setup lang="ts">

import {
	computed,
	onMounted,
} from 'vue';

import Chatbot from '@zyro-inc/site-modules/components/chatbot/Chatbot.vue';
import { useSiteAiAssistant } from '@zyro-inc/site-modules/use/useSiteAiAssistant';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { PAGE_ID_PRIVATE } from '@zyro-inc/site-modules/constants';

interface Props {
	isInPreviewMode: boolean;
}

const {
	meta,
	currentPageId,
} = useSiteGlobal();

const isCurrentPagePrivate = computed<boolean>(() => currentPageId.value === PAGE_ID_PRIVATE);
const isAiSalesAssistantEnabled = computed<boolean>(() => meta.value.aiSalesAssistant?.isEnabled);

const props = defineProps<Props>();

const {
	chatbotStart,
	chatbotRespond,
	conversationHistory,
	isChatbotResponding,
	chatbotRestart,
	isChatbotRestarting,
	chatbotTexts,
	checkAiAssistantLimits,
	isAiAssistantVisible,
} = useSiteAiAssistant(props.isInPreviewMode);

onMounted(() => {
	if (isAiSalesAssistantEnabled.value) {
		checkAiAssistantLimits();
	}
});
</script>

<template>
	<Chatbot
		v-if="isAiAssistantVisible && isAiSalesAssistantEnabled && !isCurrentPagePrivate"
		:top-position="100"
		:conversation-history="conversationHistory"
		:is-responding="isChatbotResponding"
		:is-restarting="isChatbotRestarting"
		:are-actions-disabled="isInPreviewMode"
		:texts="chatbotTexts"
		is-mobile-enabled
		is-icon-button
		@initialize="chatbotStart"
		@respond="chatbotRespond"
		@restart="chatbotRestart"
	/>
</template>
